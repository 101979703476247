<template>
  <section class="portfolio-home">
    <div class="container">
      <h1 class="portfolio-home__title">
        The work we are proud to demonstrate
      </h1>
      <p class="portfolio-home__text">
        The more successful a project, the less likely a client is to reveal it was outsourced. As a result, we can’t publicly showcase some of our most prominent work.<br /><br />
        With nearly 20 years in the industry, you'll likely find a project below similar to what you're envisioning.<br /><br />
      </p>
      <div class="portfolio-home__columns">
        <div class="portfolio-home__column">
          <p class="portfolio-home__text"><strong>Can’t find what you're looking for?</strong></p>
          <button class="button primary large portfolio-home__btn" @click="this.$router.push('/advanced-portfolio')">
            <span class="button-content">
              Check out our advanced portfolio
            </span>
          </button>
        </div>
        <div class="portfolio-home__column">
          <p class="portfolio-home__text"><strong>Still no match?</strong></p>
          <ContactsButton :desc="buttonText" :id="btnModalId" size="large" />
        </div>
      </div>
      <p class="portfolio-home__cite">
        If we can’t show something similar, we’ll offer 8 hours of development, consulting, or analytics—completely FREE!
      </p>
      <a class="portfolio-home__link" href="#" @click="showModal = true">
        {{ linkText }}
      </a>
      <ModalContact v-if="showModal" @close="showModal = false" :startProjectModal="false" :moreTextGetInTouch="linkText" />
    </div>
  </section>
</template>

<script>
import ContactsButton from "./ContactsButton.vue";
import ModalContact from "./ModalContact.vue";

export default {
  components: {
    ContactsButton,
    ModalContact
  },
  data() {
    return {
      showModal: false,
      buttonText: 'Contact us directly with your requirements and we will show you why we are always/usually ahead of the game.',
      linkText: 'Apply for a full workday with one of our developers at no cost!',
      btnModalId: 'portfolio-home-section-modal',
    }
  },
  mounted() {
    let url = new URL(window.location.href);
    if (url.searchParams.get('get-in-touch') !==  null) {
      this.showModal=true;
    }

  }

};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.portfolio-home {
  padding: 60px 0 0;

  @include media(">=desktop") {
    padding: 120px 0 0;
  }

  @include media(">=widescreen") {
    padding: 240px 0 0;
  }

  &__title {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 111%;
    text-align: center;
    text-transform: uppercase;
    color: $black;

    @include media(">=tablet") {
      margin-bottom: 50px;
      font-size: 52px;
      text-align: left;
    }

    @include media(">=desktop") {
      max-width: 1150px;
      margin-bottom: 60px;
      font-size: 62px;
    }

    @include media(">=widescreen") {
      margin-bottom: 80px;
      font-size: 72px;
    }
  }

  &__text,
  &__cite {
    max-width: 1150px;
    font-size: 18px;
    color: $base-text-color;
  }

  &__text {
    margin-bottom: 20px;
    line-height: 167%;

    @include media(">=tablet") {
      font-size: 22px;
    }

    @include media(">=desktop") {
      font-size: 24px;
    }
  }

  &__cite {
    margin-top: 40px;
    font-style: italic;
    line-height: 160%;

    @include media(">=desktop") {
      font-size: 20px;
    }
  }

  &__link {
    font-weight: 500;
    text-decoration: none;
    color: $red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__btn {
    min-height: 52px;
    margin-bottom: 40px;
  }

  &__columns {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @include media(">=tablet") {
      flex-direction: row;
      gap: 80px;
    }
  }
}
</style>
